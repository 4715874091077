<!--  -->
<template>
  <div class="audio-wrap">
    <div @click="changeType" v-if="type" class="add">
      <div class="add-btn"></div>
      <div class="ml-2 font-2">添加音频</div>
    </div>
    <div v-else>
      <div class="flex-c">
        <div class="mr-8">选择音频：</div>

        <el-popover
          :disabled="!audioModel"
          placement="top-start"
          width="200"
          trigger="hover"
          :content="`音频名称： ${audioModel ? audioModel.audioName : ''}`"
        >
          <el-button
            style="width: 100px"
            class="mr-5"
            slot="reference"
            type="primary"
            size="mini"
            @click="dialog = true"
          >
            选择音频
          </el-button>
        </el-popover>

        <AudioItem
          :isReverse="true"
          ref="audioItem"
          v-if="audioItem"
          class="mr-6"
          :isOnlyProgress="true"
          :audioItem="audioItem"
        />
        <div @click="changeType" class="pointer delBtn"></div>
      </div>
      <div v-if="audioItem && false" class="flex-c mt-3">
        <div class="mr-8">播放设置：</div>
        <div @click="changeisLoop(1)" class="my-radio">
          <div v-if="isLoop === 1" class="my-radio-choice"></div>
        </div>
        <div class="font-2">仅播放一次</div>
        <div @click="changeisLoop(2)" class="my-radio ml-5">
          <div v-if="isLoop === 2" class="my-radio-choice"></div>
        </div>
        <div>循环播放</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialog"
      :close-on-click-modal="false"
      width="60%"
      append-to-body
    >
      <div class="dialog-content">
        <div class="step2">
          <el-select
            v-model="model.scenarioId"
            style="width: 100px"
            size="mini"
            placeholder="关联剧本"
          >
            <el-option label="全部剧本" :value="''"></el-option>
            <el-option
              v-for="(option, oi) in scenarioList"
              :key="oi"
              :label="option.scenarioName"
              :value="option.id"
            ></el-option>
          </el-select>

          <el-select
            v-model="model.category"
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="类别"
          >
            <el-option label="全部类别" :value="''"></el-option>
            <el-option
              v-for="(option, oi) in audioTypeList"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select
            v-model="model.timeLength"
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="时长"
          >
            <el-option label="全部时长" :value="''"></el-option>
            <el-option
              v-for="(option, oi) in audioTimeLengthList"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select
            v-model="model.theme"
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="题材"
          >
            <el-option label="全部题材" :value="''"></el-option>
            <el-option
              v-for="(option, oi) in list1"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-input
            v-model="model.searchKey"
            size="mini"
            style="margin-left: 10px; width: 200px"
            placeholder="搜索关键词"
          ></el-input>
        </div>

        <div class="step3">
          <div
            class="item"
            @click="choiceAudio(item)"
            @dblclick="surePush(item)"
            v-for="(item, i) in audioList"
            :key="i"
          >
            <div class="right" v-if="audioModel && audioModel.id === item.id">
              <img src="@/assets/check.png" style="width: 30px; height: 30px" />
            </div>
            <AudioItem :audioItem="item" />
            <div class="item-message">
              <div class="row1">
                {{ item.audioName || "未命名" }}
              </div>
              <div class="row2">
                <span>{{ fomatType(item.theme) }}</span>
                <span style="color: rgba(153, 153, 153, 1)">{{
                  timestampToDate(item.createTime)
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="step4">
          <el-pagination
            :total="total"
            :current-page.sync="model.currPage"
            :page-size.sync="model.limit"
            layout="prev, pager, next"
          ></el-pagination>
        </div>

        <div class="step4">
          <el-button
            @click="surePush"
            style="
              width: 80px;
              border: 1px solid rgba(250, 225, 238, 1);
              background: rgba(250, 225, 238, 1);
              color: #333;
            "
            type="warning"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AudioItem from "@compts/common/audioItem.vue";
export default {
  components: {
    AudioItem,
  },
  props: ["audioVO", "playMode"],
  data() {
    return {
      dialog: false,
      model: {
        currPage: 1,
        limit: 10,
        scenarioId: "",
        searchKey: "",
        theme: "",
        timeLength: "",
        category: "",
      },
      type: true,
      isLoop: 1,
      audioItem: null,
      audioList: [],
      total: 0,
      audioModel: null,
    };
  },
  mounted() {
    if (!this.$store.state.dict.audioType) {
      this.$store.dispatch("getDicts", ["audioType", "audioTimeLength"]);
    }
  },
  computed: {
    ...mapState({
      scenarioList: (state) => state.scenarioList,
      list1: (state) => state.dict.list1,
      list4: (state) => state.dict.list4,
      audioTypeList: (state) => state.dict.audioType,
      audioTimeLengthList: (state) => state.dict.audioTimeLength,
    }),
    // audioName() {
    //   return (audioVO&& audioVO.audioName) ||
    // }
  },
  watch: {
    model: {
      handler() {
        this.dialog && this.getAudioList();
      },
      deep: true,
      immediate: true,
    },
    audioVO: {
      handler(v) {
        if (v && v.url) {
          this.audioItem = JSON.parse(JSON.stringify(v));
          this.audioItem.audioUrl = v.url;
          if (!this.audioModel) {
            this.audioModel = v;
          }
          // this.isLoop = v.playMode;
          this.type = false;
        } else {
          this.type = true;
        }
      },
      immediate: true,
    },
    dialog(v) {
      v && this.getAudioList();
    },
    playMode: {
      handler(v) {
        if (v) {
          this.isLoop = v;
        }
      },
      immediate: true,
    },
  },

  methods: {
    fomatType(type) {
      return this.list1.find((item) => item.dictValue == type).dictLabel;
    },
    changeType() {
      const { type } = this;
      if (!type) {
        if (this.audioItem?.audioUrl) {
          this.$confirm("确认删除此音频", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.audioItem = null;
            // this.isLoop = 1;
            this.$refs.audioItem && this.$refs.audioItem.change(false);
            this.type = !type;
            this.$emit("putAudio");
          });
          return;
        }
      }
      // this.isLoop = 1;
      this.type = !type;
    },
    choiceAudio(item) {
      this.audioModel = item;
    },
    pushAudio(item) {
      this.choiceAudio(item);
      this.surePush();
    },
    changeisLoop(type) {
      this.isLoop = type;
      if (this.audioItem) {
        this.audioItem.playMode = this.isLoop;
        this.$emit("putAudio", this.audioItem);
      }
    },
    surePush() {
      this.audioItem = this.audioModel;
      this.dialog = false;
      this.audioItem.playMode = this.isLoop;
      this.$emit("putAudio", this.audioItem);
    },
    async getAudioList() {
      const res = await this.$api.source.getAudioList(this.model);
      this.audioList = res.data;
      this.total = res.count;
    },
  },
  destroyed() {},
};
</script>
    
<style lang='scss' scoped>
.audio-wrap {
  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $purple;
  }
  .add-btn {
    @include wh(18px, 24px);
    @include img("../../assets/add_btn_n.png");
  }
  .delBtn {
    @include wh(18px, 24px);
    @include img("../../assets/del_btn_n.png");
  }
}
.dialog-content {
  .step3 {
    height: 45vh;
    overflow-y: scroll;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    .item {
      position: relative;
      margin-bottom: 40px;
      margin-right: 20px;
      width: 198px;
      cursor: pointer;
      .right {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 2;
      }
      .item-message {
        font-size: 14px;
        .row1 {
          margin-top: 10px;
        }
        .row2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .step4 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>