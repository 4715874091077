<template>
  <!-- 富文本编辑框 -->
  <div class="editText">
    <el-button :type="type" :size="size" @click="dialog = true">
      {{ text }}
    </el-button>

    <el-dialog
      :visible.sync="dialog"
      :close-on-click-modal="false"
      width="70%"
      :lock-scroll="true"
    >
      <!-- 调用element 组件上传图片 -->

      <el-upload
        v-show="false"
        class="avatar-uploader cus-avatar-uploader"
        :action="$config.VUE_APP_URL + $filesUrl[0]"
        :show-file-list="false"
        :on-success="uploadSuccess"
        :headers="{
          Authorization: `bearer ${accessToken}`,
        }"
        :data="{
          fileType: 1,
        }"
        :before-upload="beforeUpload"
      >
        <!-- <img v-if="item.picturePath" :src="item.picturePath" class="avatar" />
        <div v-else>
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div>图片要求：</div>
          <div>PNG格式，2M以内大小。</div>
        </div> -->
      </el-upload>

      <div class="row">
        <div class="dialog-title">
          {{ title }}
        </div>
      </div>

      

      <div>
        <el-button
          style="float: right; margin: 10px 10px 0 0"
          @click="addCutLine"
          size="mini"
          >分割线</el-button
        >
        <quill-editor
          class="dialog-editor ql-editor"
          v-model="afterContent"
          :options="quillOptions"
          ref="QuillEditor"
        >
        </quill-editor>
      </div>

      <div class="row" style="margin-top: 20px">
        <el-button @click="surePush" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const handlers = {
  image: function image() {
    document.querySelector(".cus-avatar-uploader input").click();
  },
};

export default {
  props: {
    type: {
      default: "primary",
    },
    text: {
      default: "编辑文本",
    },
    size: {
      default: "mini",
    },
    title: {
      default: "编辑文本",
    },
    content: {
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      accessToken: localStorage.getItem('access_token'),
      afterContent: "",
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [
                {
                  color: [
                    "",
                    "#ff87bf",
                    "#ffeb9d",
                    "#9dffd7",
                    "#31aee9",
                    "#eea3ff",
                  ],
                },
                { background: [
                  ''
                ] },
              ],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["link", "image", "video"],
            ],
            handlers: handlers, // 事件重写
          },
        },
        // theme: "snow",
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.afterContent = JSON.parse(JSON.stringify(this.content));
        this.$nextTick(() => {
          this.$refs.QuillEditor.quill.blur();
          this.$refs.QuillEditor.quill.setSelection(
            this.afterContent.length + 1
          );
        });
      }
    },
  },
  methods: {
   
    uploadSuccess (res) {
      let str = `<img style="width:100%" class="width-100" src="${res.url}"  alt="图片无法显示">`
      this.afterContent += str
    },
    beforeUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPG = file.type === 'image/jpeg'
      const isGIF = file.type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error('上传图片只能是 PNG或JPG, GIF格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return (isPNG || isJPG || isGIF) && isLt2M
    },
    addCutLine() {
      // let index = this.$refs.QuillEditor.quill.selection.savedRange.index;
      // this.$refs.QuillEditor.quill.insertText(
      //   index,
      //   "\n-----------------------------\n"
      // );
      this.afterContent += `<p>-----------------------------</p>`
    },
    surePush() {
      let content = this.afterContent.replace(/<img/g, "<img style='width:100%'")
      content = this.afterContent.replace(/<p>---/g, "<p style='text-align: center'>---")
      this.$emit("surePush", content);
      this.dialog = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.row {
  display: flex;
  justify-content: center;
}
.dialog-title {
  width: 152px;
  height: 24px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: #333;
}
.dialog-editor {
  margin-top: 20px;
}
</style>

<style lang='sass' scoped>
::v-deep .ql-editor
  height: 360px
  ::v-deep .dialog-editor .ql-toolbar
    border-radius: 4px 4px 0 0 !important
    border-color: RGBA(197, 192, 224, 1)
    background: RGBA(236, 234, 250, 1)
  ::v-deep .dialog-editor .ql-container
    border-radius: 0 0 4px 4px !important
</style>

